@import 'core';

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
div#__next {
  width: 100%;
  height: 100%;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;

  &:has(.ids-modal.is-open),
  &:has(.ids-main-navigation.menu-opened) {
    overflow: hidden;
  }
}

body {
  font-family: 'Brown LL Pan', sans-serif;
  line-height: 150%;
  font-size: 1rem;
  letter-spacing: 0;
  font-feature-settings: 'liga';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  margin: 0;

  .HighlightedText {
    padding: 2px 4px;
    background-color: #141414;
    color: white;
    border-radius: 4px;
  }
}

p {
  margin: 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.simplebar-vertical {
  width: 8px !important;
}

.simplebar-content-wrapper:focus-visible {
  outline: none;
}

.ReactModal__Body--open #page {
  filter: blur(10px);
  overflow: hidden;
}

.NoBlur {
  filter: none !important;
}

.GradientCard {
  border: 0;

  &.GradientCardDark {
    background: var(--vfq-card-wrapper-background-color-gradient-dark);

    ul {
      svg {
        fill: #fff;
      }
    }
  }

  &.GradientCardLight {
    background: var(--vfq-card-wrapper-background-color-gradient-premium);
  }
}

.GradientTag {
  position: relative;
  background:
    linear-gradient(#ffffff, #ffffff) padding-box,
    var(--vfq-card-wrapper-background-color-gradient-premium) border-box;
  transform: translate(0);
  transform-style: preserve-3d;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--vfq-card-wrapper-background-color-gradient-premium);
    filter: blur(7.5px);
    transform: translate3d(0px, 0px, -1px);
  }

  &.IconOnly .label {
    display: none;
  }
}

.QuizHeader {
  margin-bottom: 2rem;

  &.Locked {
    @include from-mq-sm {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .QuizTitle {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    &.Locked {
      flex-direction: column;
    }

    @include from-mq-md {
      column-gap: 1rem;
    }

    .ids-icon {
      fill: #eb1414;
    }
  }

  .ids-text.is-lg.QuizTitleSubtext p {
    padding-bottom: 0;
    font-size: 1.125rem;
  }
}

.QuizContent,
.QuizContentContainer {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.QuizCard {
  border-radius: var(--vfq-card-wrapper-border-radius-md);

  .ids-text.is-lg.QuizCardQuestion {
    p {
      display: flex;
      column-gap: 0.25rem;
      padding: 0;

      span {
        font-size: 1.125rem;
      }
    }
  }

  p {
    padding-bottom: 0;
  }
}

.ids-modal.GradientModalDark {
  .modal-body {
    border-radius: 1rem;
    background: linear-gradient(315deg, #141414 0%, #141414 60%, #5e5e5e 100%);
    row-gap: 0;
    max-width: 26.875rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include from-mq-sm {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }

  &.OneSide {
    .modal-header,
    .modal-main,
    .modal-footer {
      padding: 0 1rem;

      @include from-mq-sm {
        padding: 0 2.5rem;
      }
    }
  }

  &.OneSide,
  &.TwoSides {
    .modal-body {
      padding: 0;
    }

    .modal-header,
    .modal-main,
    .modal-footer {
      padding: 0;

      @include from-mq-sm {
        padding: 0;
      }
    }
  }

  &.TwoSides {
    .modal-body {
      @include from-mq-md {
        max-width: 53.75rem;
        width: inherit;
      }
    }
  }
}

.ids-text {
  &.is-light {
    strong {
      font-weight: var(--ids-text-body-typeface-regular-lg-font-weight);
    }
  }
}

.IconBrand {
  fill: #eb1414;
}

.VisuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
